<template>
  <div class="admin-box">
    <div class="admin-body">
      <div class="admin-body-header">角色列表</div>
      <div class="table-box" style="text-align: left">
        <van-button
          type="primary"
          size="mini"
          style="margin-bottom: 10px"
          @click="openFrom()"
          >新增角色</van-button
        >
        <table class="fl-table">
          <thead>
            <tr>
              <th>名称</th>
              <th>标识</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in adminList" :key="item">
              <td>{{ item.name }}</td>
              <td>{{ item.slug }}</td>
              <td>
                <van-button type="default" size="mini" @click="openFrom(item)"
                  >编辑</van-button
                >
                <van-button
                  type="danger"
                  size="mini"
                  style="margin-left: 10px"
                  @click="delUser(item.id)"
                  >删除</van-button
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="pagination-box d-flex align-center"
          style="margin-top: 10px; padding-right: 20px; justify-content: flex-end"
        >
          <div class="pagination">
            <van-pagination
              v-model="currentPage"
              :page-count="pageCount"
              :show-page-size="3"
              force-ellipses
            />
          </div>
        </div>
      </div>
    </div>
    <div class="edit-user-data">
      <van-popup
        v-model:show="showFrom"
        style="width: 90%; height: 45%"
        class="d-flex justify-center align-center"
        closeable
      >
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="state.name"
              name="name"
              label="名称"
              placeholder="名称"
              :rules="[{ required: true, message: '请填写名称' }]"
            />
            <van-field
              v-model="state.slug"
              name="slug"
              label="唯一标识"
              placeholder="唯一标识"
              :rules="[{ required: true, message: '请填写唯一标识' }]"
            />
            <van-field
              v-model="state.permission_str"
              type="permission_ids"
              name="权限ID集合"
              label="权限ID集合"
              placeholder="点击选择权限ID集合"
              disabled
              @click="show = true"
            />
          </van-cell-group>
          <div class="submit-btn">
            <van-button round block type="primary" native-type="submit">
              提交
            </van-button>
          </div>
        </van-form>
      </van-popup>
    </div>
    <van-dialog
      v-model:show="show"
      title="权限列表"
      show-cancel-button
      @cancel="cancelAuth"
      @confirm="confirmAuth"
    >
      <div class="check-box">
        <van-checkbox-group v-model="checked" direction="horizontal">
          <van-checkbox v-for="at in authList" :key="at" :name="at.name">{{
            at.name
          }}</van-checkbox>
          <!-- <van-checkbox name="b">复选框 b</van-checkbox> -->
        </van-checkbox-group>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import httpRole from "../../../api/role";
import httpAuth from "../../../api/auth";
import {
  Pagination,
  Button,
  Form,
  Popup,
  CellGroup,
  Field,
  Popover,
  Dialog,
  Toast,
  CheckboxGroup,
  Checkbox,
} from "vant";
export default {
  components: {
    "van-pagination": Pagination,
    "van-button": Button,
    "van-popup": Popup,
    "van-form": Form,
    "van-cell-group": CellGroup,
    "van-field": Field,
    "van-popover": Popover,
    "van-dialog": Dialog.Component,
    "van-checkbox-group": CheckboxGroup,
    "van-checkbox": Checkbox,
  },
  name: "StaffList",
  setup() {
    const active = ref(0);
    const currentPage = ref(1);
    const showFrom = ref(false);
    const showPopover = ref(false);
    const show = ref(false);
    const checked = ref([]);
    const actions = [{ text: "正常" }, { text: "禁用" }];
    const userStatus = ref(1);
    const onSelect = (action) => {
      if (action.text === "正常") {
        userStatus.value = 1;
      } else {
        userStatus.value = 2;
      }
    };

    // 确定权限
    const confirmAuth = () => {
      let str = "";
      const ids = [];
      checked.value.forEach((el) => {
        str += el + "、";
        authList.value.forEach((ele) => {
          if (ele.name == el) {
            ids.push(ele.id);
          }
        });
      });
      state.permission_str = str;
      state.permission_ids = ids;
      show.value = false;
    };

    // 取消权限
    const cancelAuth = () => {
      if (!isEdit) {
        checkAuth.value = [];
        state.permission_ids = [];
      }
      show.value = false;
    };
    // 删除
    const delUser = (id) => {
      Dialog.confirm({
        title: "删除",
        message: "是否删除该角色",
      })
        .then(async () => {
          // on confirm
          const res = await httpRole.del({ ids: [id] });
          if (res.code == 200) {
            Toast(res.msg);
            getAdminUserList();
          }
        })
        .catch(() => {
          // on cancel
          Toast("取消");
        });
    };

    // 获取管理员列表
    const adminList = ref([]);
    const pageCount = ref(1);
    const getAdminUserList = async () => {
      const res = await httpRole.query();
      if (res.code == 200) {
        adminList.value = res.data.list;
        pageCount.value = res.data.pageCount;
        // console.log(adminList);
      }
    };
    getAdminUserList();

    const state = reactive({
      name: "",
      slug: "",
      permission_ids: [],
      permission_str: "",
    });

    const isEdit = ref(false);
    const openFrom = (item) => {
      if (item) {
        isEdit.value = true;
        state.name = item.name;
        state.id = item.id;
        state.slug = item.slug;
        let str = "";
        const arr = [];
        const ids = [];
        item.permissions.forEach((el) => {
          str += el.name + "、";
          arr.push(el.name);
          ids.push(el.id);
        });
        state.permission_ids = ids;
        state.permission_str = str;
        checked.value = arr;
      }
      showFrom.value = true;
    };

    // 新增、编辑
    const onSubmit = async () => {
      // console.log('编辑');
      if (isEdit.value) {
        const params = {
          id: state.id,
          slug: state.slug,
          name: state.name,
          permission_ids: state.permission_ids,
        };
        const res = await httpRole.update(params);
        if (res.code == 200) {
          Toast(res.msg);
          getAdminUserList();
        }
      } else {
        const params = {
          id: state.id,
          slug: state.slug,
          name: state.name,
          permission_ids: state.permission_ids,
        };
        const res = await httpRole.create(params);
        if (res.code == 200) {
          Toast(res.msg);
          getAdminUserList();
        }
      }
      showFrom.value = false;
    };
    // 监听表单关闭
    watch(showFrom, (val) => {
      if (!val) {
        isEdit.value = false;
        state.name = "";
        state.slug = "";
        state.permission_ids = "";
        checked.value = [];
        state.permission_str = "";
      }
    });

    // 获取权限列表
    const authList = ref([]);
    const getAuthList = async () => {
      try {
        const res = await httpAuth.query();
        if (res) {
          const arr = [];
          res.data.forEach((el) => {
            arr.push(el);
            if (el.children) {
              el.children.forEach((ele) => {
                arr.push(ele);
              });
            }
          });
          authList.value = arr;
        }
      } catch (error) {
        // console.log(error);
        Toast("获取权限列表失败");
      }
    };
    getAuthList();
    return {
      active,
      currentPage,
      showFrom,
      state,
      actions,
      showPopover,
      userStatus,
      adminList,
      pageCount,
      isEdit,
      show,
      checked,
      authList,

      // method
      onSelect,
      onSubmit,
      delUser,
      openFrom,
      confirmAuth,
      cancelAuth,
    };
  },
};
</script>
<style scoped lang="less">
.tabbar-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
}
.table-box {
  //   margin: 0 20px;
  box-sizing: border-box;
}
.fl-table {
  border-radius: 10px;
  font-size: 24px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
  thead {
    th {
      color: #ffffff;
      background: #4fc3a1;
    }
    th:nth-child(odd) {
      color: #ffffff;
      background: #324960;
    }
  }
  td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }
  td,
  th {
    text-align: center;
    padding: 8px;
    font-size: 36px;
  }
  tr:nth-child(even) {
    background: #f8f8f8;
  }
}
tr {
  height: 80px;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}
.admin-body-header {
  margin: 40px;
  font-weight: 800;
  font-size: 40px;
}
.user-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 28px;
  margin-left: 60px;
  padding: 20px 0;
  color: #646566;
}
.gt-span {
  margin-left: 10px;
  display: inline-block;
  transform: rotate(90deg);
}
.submit-btn {
  margin-top: 90px;
  margin-right: 20px;
  margin-left: 20px;
}
.span-status {
  margin-right: 60px;
}
.check-box {
  display: flex;
  padding: 0 20px;
  flex-wrap: wrap;
  margin: 20px 0;
  overflow-y: auto;
  height: 450px;
}
.van-checkbox--horizontal {
  margin: 20px !important;
  width: 340px !important;
}
::v-deep .pagination .van-pagination__item {
  height: 64px !important;
}
::v-deep .van-field--disabled .van-field__value,
::v-deep .van-field--disabled .van-field__label {
  color: #646566;
}
</style>
